<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        <!-- 测试更新 -->
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游东京必备的景点套票，精选多个人气设施，交通套票与美食店家，一周内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ●同设施只可以使用一次，相同设施不可再入场。<br>
            ●票劵使用效期为购买日起<span>90天内</span><br>
            EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
            ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
            ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
            EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
            ●有效期间内可任<span>选3项设施</span><br>
            ●注意: 单次购买複数票劵时，当其中一张票劵开始用，所有票劵也将同步开通 (<span>使用效期7天</span>)<br>
            ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
             EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
            ●无使用完毕不能兑现<br>
            ●请事先于以下连结确认各设施注意事项・营业时间・
            公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i">{{title}}</div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='巴士路线' && !contentItem.noColor">{{cItem}}<br></a>
                    <p class="gfwz" v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hftokyo/zh/havefun_title-sc.png'),
      loadImg: require('@/assets/images/hftokyo/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hftokyo/グループ 573.png'),
          title: ['东京迷你世界博物馆'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['东京都江东区有明1丁目3-33 有明物流中心'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.smallworlds.jp/cn/'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['如因天灾地震事故，或飞机停飞、电车误点，导致本馆不可抗力休馆时无法退款。'] },
                { cid: 2, text: ['如入场券遗失或被盗不能再次补发门票。'] },
                { cid: 3, text: ['退场后无法再次入场。'] }
              ]
            }]
        },
        {
          id: 2,
          img: require('@/assets/images/hftokyo/グループ 574.png'),
          title: ['电子成田利木津巴士单程票'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['①成田机场第1航站楼1F到达大厅(南口1)'] }, { cid: 2, text: ['②成田机场第2航站楼1F到达大厅(南口)'] }, { cid: 3, text: ['③成田机场第3航站楼主楼1F'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://webservice.limousinebus.co.jp/web/en/Top.aspx'] }]
            },
            {
              msgId: 3,
              title: '巴士路线',
              content: [{ cid: 1, text: ['https://www.tripellet.com/limousine/route_list.pdf'] }]
            },
            {
              msgId: 4,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['PDF电子票无法直接乘车，务必到利木津巴士柜台兑换后乘车。'] },
                { cid: 2, text: ['因根据路线运行班次不同，请事先确认运行状况。'] },
                { cid: 3, text: ['由于巴士在通常的道路 (包括高速公路) 上行驶，因此平日早晚的自然拥堵或旅游旺季等，可能会因道路情况而延迟。请留出充裕的时间搭乘。'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hftokyo/グループ 575.png'),
          title: ['京急电铁三崎鲔鱼之旅一日游'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['东京都港区高轮3-26-26 (品川站)'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.haneda-tokyo-access.com/cn/ticket/discount/maguro-day.html'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['兑换后的乘车券限兑换当日有效。'] },
                { cid: 2, text: ['兑换后的车票无法退款。'] },
                { cid: 3, text: ['加盟店舖的营业时间及休业日有可能更动。使用前请务必与店舖、设施确认后再行光顾。'] },
                { cid: 4, text: ['加盟店舗有人群拥挤的可能性。届时，需要与一般顾客一同等待。此票券并无优先权。'] },
                { cid: 5, text: ['加盟店铺有权在该菜单、商品售完时，以其他商品代替的权力。请事先谅解。<br>三崎鲔鱼之旅的加盟店铺情报等，详情请至以下连结查看。','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="https://www.keikyu.co.jp/visit/otoku/otoku_maguro/">https://www.keikyu.co.jp/visit/otoku/otoku_maguro/</a>'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hftokyo/グループ 576.jpg'),
          title: ['姆明主题乐园门票 & 西武铁道旅游通行券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['东京都丰岛区南池袋1-28-1'] },{ cid: 2, text: ['西武铁道 池袋站 (西武池袋旅游服务中心)'] },{ cid: 3, text: ['特急券售票窗口对面 '] },{ cid: 4, text: ['※不可至「姆明主题乐园」进行兑换'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.seiburailway.jp/railways/tourist/china/ticket/metsa.html'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['产品内容: 西武线全线自由搭乘1日劵、<br>姆明主题乐园1日通行券、<br>姆明主题乐园往返巴士 (饭能站和东饭能站出发)'] },
                { cid: 2, text: ['本商品为外国人专用，兑换票券时，必须出示旅客护照。'] },
                { cid: 3, text: ['亦可出示护照影本、照片、或是居留证。'] },
                { cid: 4, text: ['无法在换票地点进行票券变更或取消。'] },
                { cid: 5, text: ['票券的使用期限为兑换后2週内。'] },
                { cid: 6, text: ['【数量限定优惠】限定200名赠送「别针徽章」，送完为止。'] }
              ]
            }
          ]
        },
        // {
        //   id: 5,
        //   img: require('@/assets/images/hftokyo/グループ 577.png'),
        //   title: ['KOBE FARBRETON 新宿 3000日圆优惠券'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兑换地点',
        //       content: [{ cid: 1, text: ['东京都新宿区新宿 3-20-8 TOPS HOUSE 新宿横丁'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方网站',
        //       content: [{ cid: 1, text: ['https://kobe-farbreton.com/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事项',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['可以享受3000日圆优惠券。'] },
        //         { cid: 2, text: ['若有价差需现场支付差额。'] },
        //         { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] }
        //         ]
        //     }
        //   ]
        // },
        {
          id: 6,
          img: require('@/assets/images/hftokyo/グループ 578.png'),
          title: ['野乃鸟 3000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '●新宿店',
                '东京都新宿区新宿 3-20-8 TOPS HOUSE 新宿横丁',
                '●日本橋',
                '东京都千代田区锻冶町1－9－19 GEMS 日本桥６F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['野乃鳥 KOBE YAKITORI STAND新宿店'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/honten/'] },
                { cid: 3, text: ['野乃鳥 日本橋'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/%e9%87%8e%e4%b9%83%e9%b3%a5-%e6%97%a5%e6%9c%ac%e6%a9%8b/'] }
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受3000日圆优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['3000日元优惠券可从以下店铺中任选一家做使用。<br>(野乃鸟 KOBE YAKITORI STAND新宿、野乃鸟 日本桥)'] },
                { cid: 4, text: ['入店时需额外支付小菜费用。'] },
                { cid: 5, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                { cid: 6, text: ['结帐时出示Travel Contents APP扫码<br>就可享有10%点数回馈。APP下载连结：<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="https://tripellet.com/tcapp-download-b">https://tripellet.com/tcapp-download-b</a>'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hftokyo/グループ 579.png'),
          title: ['东京DOME CITY游乐设施5次券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '东京都文京区后乐1-3-61 法人Service Counter',
                '(MEETS PORT 2楼)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.tokyo-dome.co.jp/zh-CHT/tourists/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间与各游乐设施有可能突然变更，请事先于官网确认。'] },
                { cid: 2, text: ['本商品为外国人专用，兑换票券时，必须出示旅客的护照。'] },
                { cid: 3, text: ['本商品限1人使用。多数人使用时须购买人数份票券。<br>(2位时需购买2张)。'] },
                { cid: 4, text: ['请遵照各设施搭乘年龄限制。'] },
                { cid: 5, text: ['SENTAI LAND和期间限定设施不可使用。'] }
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hftokyo/JOYPOLIS.jpg'),
          title: ['东京台场JOYPOLIS PASSPORT (入场+游乐设施无限搭乘) '],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒135-0091 东京都港区台场1丁目6番1号 DECKS Tokyo Beach 3F～5F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://tokyo-joypolis.com/language/ch/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间与各游乐设施有可能突然变更，请事先于官网确认。'] }
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFTokyo&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-tokyo-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%; 
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
      .el-image {
        width: 100%;
      }
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }

        /deep/.gfwz {
          span {
            color: #FBB03B;
          }
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>